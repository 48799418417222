<template>
    <div class="card">
        <div class="bg-blue-light px-1 rounded pb-2">
            <TitleButton
                class="mt-1"
                btnTitle="List"
                :showBtn="true"
                :showAddNew="false"
                title="New Stock Adjustment"
                @onClickCloseButton="navigateToListPage"
            />

            <div class="row mt-2 gy-1">
                <div class="row mt-1">
                    <div class="col-sm-4">
                        <label class="align-middle">Warehouse</label>
                        <v-select
                            placeholder="Select Warehouse"
                            v-model="formData.warehouse_id"
                            :options="warehouses"
                            label="text"
                            :reduce="text => text.id"
                        />
                    </div>
                    <div class="col-sm-4">
                      <label for="colFormLabel">Select Project</label>
                      <v-select
                          placeholder="Select Project"
                          :options="projects"
                          label="name"
                          :reduce="name => name.id"
                          v-model="formData.project_id"
                      />
                    </div>
                  <div class="col-sm-4">
                        <label class="align-middle">Adjustment Date</label>
                        <input type="date" class="form-control" v-model="formData.date">
                    </div>
                </div>
                <div class="row mt-1">
                    <div class="col-md-4">
                        <label class="align-middle">Adjustment Account</label>
                        <v-select
                            placeholder="Select Account Head"
                            v-model="formData.account_head_id"
                            :options="accountHeads"
                            label="name"
                            :reduce="name => name.id"
                        />
                    </div>
                  <div class="col-md-4">
                    <label class="form-label">Cost Centre</label>
                    <v-select
                        placeholder="Select Cost Centre"
                        v-model="formData.cost_centre_id"
                        :options="costCentres"
                        label="name"
                        :reduce="name => name.id"
                    />
                  </div>
                    <div class="col-sm-4">
                        <label class="align-middle">Adjustment No</label>
                        <input type="text" class="form-control" v-model="formData.adjustment_no">
                    </div>
                </div>
            </div>
        </div>

        <div class="px-2 mt-2">
            <AddItemDetails
                class="mb-2"
                :products="products"
                v-for="(item, index) in formData.item_details"
                :key="index"
                :index="index"
                :item="item"
                :warehouse-id="formData.warehouse_id"
                @onClose="onClose"
            />
        </div>

        <div class="p-1">
            <div class="row mt-3 px-2">
                <div class="col-12">
                    <div class="mb-2">
                        <label for="note" class="form-label fw-bold">Memo</label>
                        <textarea v-model="formData.description" placeholder="Memo" class="form-control" rows="2"
                                  id="description"></textarea>
                    </div>
                </div>
            </div>
        </div>

        <div class="mt-2 d-flex justify-content-start justify-content-sm-center px-2 gap-1 flex-wrap pb-2">
            <hr class="hr-full-width mb-1">
            <button :disabled="saveButtonLoader || saveNewButtonLoader" @click="handleSubmit(true)"
                    class="btn btn-primary">
                Save
            </button>
            <button :disabled="saveButtonLoader || saveNewButtonLoader" @click="handleSubmit(false)"
                    class="btn btn-primary">Save & New
            </button>
            <button @click="navigateToListPage" class="btn btn-outline-secondary">Cancel</button>
        </div>

        <GlobalLoader/>
    </div>
</template>
<script setup>
import {inject, onMounted, ref}  from 'vue'
import {useRoute, useRouter}     from "vue-router"
import TitleButton               from '@/components/atom/TitleButton'
import AddItemDetails            from "@/components/molecule/procurement/inventory/stock/adjustment/AddItemDetails"
import AddButton                 from "@/components/atom/AddButton"
import handleStock               from "@/services/modules/stock";
import handleBusinessesLocations from "@/services/modules/businessesLocations";
import handleJournal             from "@/services/modules/journal";
import handlePurchaseAndSales    from "@/services/modules/purchase";
import {useStore}                from "vuex";
import handleProjects from "@/services/modules/procurement/project";
import handleCostCentres from "@/services/modules/ngo/costCentre";

const $router = useRouter()
const $route = useRoute()
const $store = useStore()
const showError = inject('showError')
const showSuccess = inject('showSuccess')
const {fetchStockAdjustmentNumber, storeStockAdjustment} = handleStock()
const {fetchBusinessLocationsList} = handleBusinessesLocations();
const {fetchAccountHeadsList} = handleJournal();
const {fetchProductList} = handlePurchaseAndSales();
const {fetchProjects} = handleProjects();
const { fetchCostCentreList } = handleCostCentres()

let saveButtonLoader = ref(false)
let saveNewButtonLoader = ref(false)
let warehouses = ref([])
let accountHeads = ref([])
let products = ref([])
let projects = ref([])
let costCentres = ref([])
let formData = ref({
    warehouse_id : null,
    project_id : null,
    cost_centre_id : null,
    date : null,
    adjustment_no : null,
    description : null,
    item_details : [
        {
            product_id : null,
            stock_in_hand : 0,
            average_price : 0,
            adjustment_qty : null,
            adjusted_stock : null,
            note : null,
        }
    ],
})

const navigateToListPage = () => {
    $router.push({name: `stock-adjustment-list`, params: $route.params, query: $route.query});
}

const handleSubmit = (redirect = false) => {
    $store.state.loading = true;
    let query = `?company_id=${$route.params.companyId}`;
    if(redirect) {
        saveButtonLoader.value = true
    } else {
        saveNewButtonLoader.value = true
    }
    if(!formData.value.date) formData.value.date = new Date().toISOString().split('T')[0]

    let copyFormData = JSON.parse(JSON.stringify(formData.value))
    copyFormData.item_details = JSON.stringify(formData.value.item_details)

    storeStockAdjustment(query, copyFormData)
        .then(res => {
            saveButtonLoader.value = false
            saveNewButtonLoader.value = false

            if(res.status) {
                showSuccess(res.message)
                if(redirect) navigateToListPage()
                resetForm()
            }
            if(!res.status) {
                showError(res.message)
            }
        })
        .catch(err=> {
            saveButtonLoader.value = false;
            saveNewButtonLoader.value = false;
            $store.state.loading = false;
            showError(err)
        })

    $store.state.loading = false;
}

const resetForm = () => {
    formData.value = {
        warehouse_id : null,
        project_id : null,
        date : null,
        adjustment_no : null,
        description : null,
        item_details : [
            {
                product_id : null,
                stock_in_hand : 0,
                average_price : 0,
                adjustment_qty : null,
                adjusted_stock : null,
                note : null,
            }
        ],
    }
}
const onClose = (index) => {
    return ;
}

onMounted(() => {
    $store.state.loading = true;
    let query = `?company_id=${$route.params.companyId}`;
    const projectQuery = `${query}&time_and_interval=1`
    formData.value.date = new Date().toISOString().split('T')[0]
    let accountHeadQuery = '?company_id=' + $route.params.companyId + '&cash_bank_hide=yes&status=active';

    const costCenterRes = fetchCostCentreList(query)
    Promise.all([
        fetchBusinessLocationsList(query).then(res => {
            if (res.data) {
                warehouses.value = res.data;
            }
        }),
        fetchStockAdjustmentNumber(query).then(res => {
            if (res.data) {
                formData.value.adjustment_no = res.data;
            }
        }),
       fetchAccountHeadsList(accountHeadQuery).then(res => {
            if (res.data) {
                accountHeads.value = res.data;
            }
        }),
        fetchProductList(query).then(res => {
            if (res.data) {
                products.value = res.data
            }
        }),
      fetchProjects(projectQuery).then(res => {
        if (res.status) {
          projects.value = res.data;
        }
      }),
      costCenterRes.then(res=> {
        if(res.data) costCentres.value = res.data
      }),
    ]);
    $store.state.loading = false;
})
</script>
